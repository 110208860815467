import {useEffect, useState} from 'react';
import {jsonReq} from "./HttpUtil";

const useFetch = (url, fetch) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        if (fetch){
            setLoading(true);
            try {
                const res = await jsonReq().get(url);
                setData(res.data.data);
            }catch (e) {
                console.log("Error fetching data: ", url, e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData()
    }, [url]);

    return {data, loading, error, retry: () => fetchData()};
};

export default useFetch;
