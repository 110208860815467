import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function EmiComfort({dataKey, emi_comfort, handleOnChange, handleListChange}) {
    return (
        <Col xs={12}>
            <span className="sub-heading">EMI Comfort</span>
            <Form.Group className="mb-3" controlId="emi_comfort_summary">
                <Form.Label>Summary</Form.Label>
                <Form.Control as="textarea" name="emi_comfort_summary" rows={12}
                              value={emi_comfort.summary}
                              onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
            </Form.Group>
            <Row>
                <Col xs={12}>
                    <span className="sub-heading">EMI Comfort Data</span>
                </Col>
                <Col xs={12}>
                    <Form.Group as={Row} className="mb-3" controlId="loan_amount">
                        <Form.Label column sm={6}>Loan Amount</Form.Label>
                        <Col sm={6}>
                            <Form.Control type="text" placeholder="" name="loan_amount"
                                          value={emi_comfort.emi_comfort.loan_amount}
                                          onChange={(ev) => handleOnChange(ev, `${dataKey}.emi_comfort.loan_amount`)}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="emi_payment_capability">
                        <Form.Label column sm={6}>EMI Payment Capability</Form.Label>
                        <Col sm={6}>
                            <Form.Control type="text" placeholder="" name="emi_payment_capability"
                                          value={emi_comfort.emi_comfort.emi_payment_capability}
                                          onChange={(ev) => handleOnChange(ev, `${dataKey}.emi_comfort.emi_payment_capability`)}/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Col>
    )
}

export default EmiComfort;
