import {getTitle} from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

function BusinessImages1({dataKey, business_images, handleOnChange, handleListChange, handleListOfListChange,
                            handleAddToList, handleRemoveFromList}) {

    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="bi_summary">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control as="textarea" name="bi_summary" rows={12}
                                          value={business_images.summary}
                                          onChange={(ev) =>
                                              handleOnChange(ev, `${dataKey}.summary`)}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default BusinessImages1;
