import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const BusinessDetails = function ({dataKey, business_details, handleOnChange}) {
    return (
        <Col xs={12} className="card card-section">
            <span className="sub-heading">Business Details</span>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_name">
                    <Form.Label column sm={6}>Business Name</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_name"
                                      value={business_details.business_name}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_name`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_activity">
                    <Form.Label column sm={6}>Business Activity</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_activity"
                                      value={business_details.business_activity}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_activity`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="years_in_business">
                    <Form.Label column sm={6}>Years in Business</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="years_in_business"
                                      value={business_details.years_in_business}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.years_in_business`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="years_in_current_location">
                    <Form.Label column sm={6}>Years in Current Location</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="years_in_current_location"
                                      value={business_details.years_in_current_location}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.years_in_current_location`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_place_ownership">
                    <Form.Label column sm={6}>Business Place Ownership</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_place_ownership"
                                      value={business_details.business_place_ownership}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_place_ownership`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_structure">
                    <Form.Label column sm={6}>Business Structure</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_structure"
                                      value={business_details.business_structure}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_structure`)}/>
                    </Col>
                </Form.Group>
        </Col>
    )
}

function FamilyDetails({dataKey, family_details, handleOnChange, handleListChange, handleRemoveFromList, handleAddToList}) {

    return (
        <Col xs={12} className="card card-section">
            <Row className="row-pad">
                <Col xs={12}><span className="sub-heading">Family Members</span></Col>
                {family_details.members && family_details.members.map((member, index ) => (
                    <Col xs={6} className="member-section">
                        <div className="col-12 member-head">
                            <a href="#"
                               onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.members`, index)}
                               className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                        </div>
                        <Form.Group as={Row} className="mb-3" controlId={`name_${index}`}>
                            <Form.Label className="lead" column sm={6}>Name</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`name_${index}`}
                                              value={member.name}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'name', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`relation_${index}`}>
                            <Form.Label className="lead" column sm={6}>Relation</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`relation_${index}`}
                                              value={member.relation}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'relation', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`is_student_${index}`}>
                            <Form.Label className="lead" column sm={6}>Is Student?</Form.Label>
                            <Col sm={6}>
                                <Form.Check type="checkbox" name={`is_student_${index}`}
                                            checked={member.is_student}
                                            onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'is_student', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`occupation_${index}`}>
                            <Form.Label className="lead" column sm={6}>Occupation</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`occupation_${index}`}
                                              value={member.occupation}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'occupation', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`age_${index}`}>
                            <Form.Label className="lead" column sm={6}>Age</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`age_${index}`}
                                              value={member.age ? member.age : ""}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'age', index)}/>
                            </Col>
                        </Form.Group>
                    </Col>))
                }
                <Col xs={6} className="member-section">
                    <a href="#"
                       onClick={(ev) => handleAddToList(ev, `${dataKey}.members`, "family_members")}
                       className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
                </Col>
            </Row>
        </Col>
    )
}

function CharacterDetails({
                              dataKey, character_details, handleOnChange, handleListChange,
                              handleAddToList, handleRemoveFromList
                          }) {
    return (
        <Col xs={12}>
            <span className="main-heading">Character Details</span>
            <Row>
                <Col xs={12} className="card card-section">
                    {/*<Form.Group className="mb-3" controlId="new_to_credit">*/}
                    {/*    <Form.Label>Is new to credit?</Form.Label>*/}
                    {/*    <Form.Check className="checkbox-custom" type="checkbox"*/}
                    {/*                onChange={(ev) => handleOnChange(ev, `${dataKey}.new_to_credit`)}*/}
                    {/*                name="new_to_credit" checked={character_details.new_to_credit}/>*/}
                    {/*</Form.Group>*/}
                    <Form.Group className="mb-3" controlId="business_summary">
                        <Form.Label>Business Summary</Form.Label>
                        <Form.Control as="textarea" name="business_summary" rows={12}
                                      value={character_details.business_summary}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_summary`)}/>
                    </Form.Group>
                    <BusinessDetails dataKey={`${dataKey}.business_details`} business_details={character_details.business_details}
                                     handleOnChange={handleOnChange}/>
                    <Form.Group className="mb-3" controlId="family_summary">
                        <Form.Label>Family Summary</Form.Label>
                        <Form.Control as="textarea" name="family_summary" rows={12}
                                      value={character_details.family_summary}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.family_summary`)}/>
                    </Form.Group>
                    {/*<FamilyDetails family_details={character_details.family_details} dataKey={`${dataKey}.family_details`}*/}
                    {/*               handleOnChange={handleOnChange}*/}
                    {/*               handleListChange={handleListChange}*/}
                    {/*               handleAddToList={handleAddToList}*/}
                    {/*               handleRemoveFromList={handleRemoveFromList}/>*/}
                </Col>
            </Row>

        </Col>
    )
}

export default CharacterDetails;