// src/components/ProductView.js

import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faEdit} from "@fortawesome/free-solid-svg-icons";

function ProductView() {
    const location = useLocation();
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {product_id} = useParams()
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })

    const [product, setProduct] = useState(() => {
        if (location.state) {
            return location.state.product;
        } else {
            return null;
        }
    });

    const handleEdit = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg, product: product };
        navigate(`/products/edit/${product_id}`, { state: data });
    };

    const handleProductList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/products/list', { state: data });
    };

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/products/list" onClick={handleProductList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            <Nav.Link href="/products/edit" onClick={handleEdit}><FontAwesomeIcon
                                icon={faEdit}/>&nbsp;&nbsp;Edit</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{product.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Code</strong></td>
                            <td>{product.code}</td>
                        </tr>
                        <tr>
                            <td><strong>Status</strong></td>
                            <td>{product.status}</td>
                        </tr>
                        <tr>
                            <td><strong>Rate of Interest</strong></td>
                            <td>
                                <Row>
                                    <Col xs={6}>
                                        <span>Minimum</span> <br/>
                                        <span>{product.roi.min} %</span>
                                    </Col>
                                    <Col xs={6}>
                                        <span>Maximum</span> <br/>
                                        <span>{product.roi.max} %</span>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Tenure</strong></td>
                            <td>
                                <Row>
                                    <Col xs={6}>
                                        <span>Minimum</span> <br/>
                                        <span>{product.tenure.min} Year(s)</span>
                                    </Col>
                                    <Col xs={6}>
                                        <span>Maximum</span> <br/>
                                        <span>{product.tenure.max} Years</span>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Loan Amount</strong></td>
                            <td>
                                <Row>
                                    <Col xs={6}>
                                        <span>Minimum</span> <br/>
                                        <span>{product.amount.min}</span>
                                    </Col>
                                    <Col xs={6}>
                                        <span>Maximum</span> <br/>
                                        <span>{product.amount.max}</span>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default ProductView;
