import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import Row from "react-bootstrap/Row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

function Expenses({dataKey, expenses, handleListOfListChange, handleAddToList, handleRemoveFromList}) {
    return (
        <Row>
            {expenses.data && expenses.data.map((expense, index ) => (
                <Col xs={12} className="csrd card-section">
                    <Form.Group as={Row} className="mb-3" controlId={`expense_${index}`}>
                        <Col sm={6}>
                            <Form.Control key={`${dataKey}_exp_inp_${index}`} type="text" placeholder="" name={`${dataKey}_expense_${index}_0`}
                                          value={expense[0]}
                                          onChange={(ev) => handleListOfListChange(ev, `${dataKey}.data`, index, 0)}/>
                        </Col>
                        <Col sm={4}>
                            <Form.Control type="text" placeholder="" name={`${dataKey}_expense_${index}_1`}
                                          value={expense[1]}
                                          onChange={(ev) => handleListOfListChange(ev, `${dataKey}.data`, index, 1)}/>
                        </Col>
                        <Col sm={2}>
                            <a href="#"
                               onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.data`, index)}
                               className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                        </Col>
                    </Form.Group>
                </Col>
            ))}
            <Col xs={12} className="mb-3">
                <a href="#"
                   onClick={(ev) => handleAddToList(ev, `${dataKey}.data`, 'expenses')}
                   className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
            </Col>
        </Row>
    )
}

function BusinessExpenses({
                              dataKey, business_expenses, handleOnChange, handleListOfListChange,
                              handleAddToList, handleRemoveFromList}) {
    return (
        <Row>
            <Col xs={12}>
                <Form.Group className="mb-3" controlId="summary">
                    <Form.Label>Summary</Form.Label>
                    <Form.Control as="textarea" name="summary" rows={10} value={business_expenses.summary} onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
                </Form.Group>
                {/*<Col xs={12}><span className="sub-heading">Business Expenses Data</span></Col>*/}

                {/*<Expenses dataKey={`${dataKey}.expenses`} expenses={business_expenses.expenses}*/}
                {/*          handleListOfListChange={handleListOfListChange}*/}
                {/*          handleAddToList={handleAddToList}*/}
                {/*          handleRemoveFromList={handleRemoveFromList}/>*/}

                {/*<Form.Group className="mb-3" controlId="total_expenses">*/}
                {/*    <Form.Label>Total Expenses</Form.Label>*/}
                {/*    <Form.Control as="input" name="total_expenses" value={business_expenses.total_expenses} onChange={(ev) => handleOnChange(ev, `${dataKey}.total_expenses`)}/>*/}
                {/*</Form.Group>*/}
            </Col>
        </Row>
    )
}

function FamilyExpenses({dataKey, family_expenses, handleOnChange, handleListOfListChange,
                            handleAddToList, handleRemoveFromList}) {
    return (
        <Col xs={12}>
            <Form.Group className="mb-3" controlId="summary">
                <Form.Label>Summary</Form.Label>
                <Form.Control as="textarea" name="summary" rows={10} value={family_expenses.summary}
                              onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
            </Form.Group>
            {/*<Col xs={12}><span className="sub-heading">Family Expenses Data</span></Col>*/}

            {/*<Expenses dataKey={`${dataKey}.expenses`} expenses={family_expenses.expenses}*/}
            {/*          handleListOfListChange={handleListOfListChange}*/}
            {/*          handleAddToList={handleAddToList}*/}
            {/*          handleRemoveFromList={handleRemoveFromList}/>*/}

            {/*<Form.Group className="mb-3" controlId="total_expenses">*/}
            {/*    <Form.Label>Total Expenses</Form.Label>*/}
            {/*    <Form.Control as="input" name="total_expenses" rows={3} value={family_expenses.total_expenses}*/}
            {/*                  onChange={(ev) => handleOnChange(ev, `${dataKey}.total_expenses`)}/>*/}
            {/*</Form.Group>*/}
        </Col>
    )
}

function ExpenseDetails({dataKey, expense_details, handleOnChange, handleListChange, handleListOfListChange,
                            handleAddToList, handleRemoveFromList}) {
    return (
        <Col xs={12}>
            <Row>
                <Col xs={12}><span className="main-heading">Expense Details</span></Col>
                <Col xs={12}>
                {/*<span className="sub-heading">Business Expenses</span>*/}
                {/*    <BusinessExpenses dataKey={`${dataKey}.business_expenses`}*/}
                {/*                      business_expenses={expense_details.business_expenses}*/}
                {/*                      handleOnChange={handleOnChange}*/}
                {/*                      handleListChange={handleListChange}*/}
                {/*                      handleListOfListChange={handleListOfListChange}*/}
                {/*                      handleAddToList={handleAddToList}*/}
                {/*                      handleRemoveFromList={handleRemoveFromList}*/}
                {/*    />*/}
                    <span className="lead">Family Expenses</span>
                    <FamilyExpenses dataKey={`${dataKey}.family_expenses`}
                                    family_expenses={expense_details.family_expenses}
                                    handleOnChange={handleOnChange}
                                    handleListChange={handleListChange}
                                    handleListOfListChange={handleListOfListChange}
                                    handleAddToList={handleAddToList}
                                    handleRemoveFromList={handleRemoveFromList}
                    />
                </Col>
            </Row>
        </Col>
    )
}

export default ExpenseDetails;