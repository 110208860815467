// src/components/OrganisationList.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { jsonReq } from "../../utils/HttpUtil";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "../../context/AuthContext";
import Button from "react-bootstrap/Button";

function ApplicationList() {
    const { user } = useAuth();
    const [applications, setApplications] = useState(null);
    const [pages, setPages] = useState({
        count: 50,
        page: 1
    });
    const [organisations, setOrganisations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [appsLoading, setAppsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { orgRole } = useData();
    const location = useLocation();
    const [selOrg, setSelOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })

    const [filterForm, setFilterForm] = useState(() => {
        if (selOrg) {
            return {org_id: selOrg._id, status: "verification_pending", page: 1}
        }
        return {org_id: "", status: "", page: 1}
    });

    useEffect(() => {
        const fetchOrgs = async () => {
            try {
                const res = await jsonReq().get("/dashboard/organisations/list");
                setOrganisations(res.data.data.organisations);
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchOrgs()
    }, [loading]);

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const res = await jsonReq().get(`/dashboard/applications/list?org_id=${filterForm.org_id}&status=${filterForm.status}&page=${filterForm.page}`)
                setApplications(res.data.data.applications)
                setPages(res.data.data.pages)
                if (organisations) {
                    setSelOrg(organisations.filter(org => org._id === filterForm.org_id)[0])
                }
            } catch (ae) {
                console.log("Error fetching application list: ", ae);
                setError(ae);
            } finally {
                setAppsLoading(false);
            }
        }
        fetchApplications()
    }, [appsLoading]);

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        if (["status", "org_id"].includes(name)) {
            setFilterForm({
                ...filterForm,
                [name]: value,
                page: 1
            });
        } else {
            setFilterForm({
                ...filterForm,
                [name]: value
            });
        }
    };

    // Filter form component
    let orgOpts = []
    if (organisations) {
        organisations.forEach(org => {
            orgOpts.push(<option value={org._id} key={org._id}>{org.name}</option>)
        })
    }

    let pageOptions = []
    if (pages) {
        for (let i=0;i<pages.count;i++) {
            pageOptions.push(<option value={i+1} key={"pg_"+i+1}>{i+1}</option>)
        }
    }

    const triggerFetchApplications = function(ev) {
        ev.preventDefault();
        setAppsLoading(true);
    }

    let filterFormComponent = (
        <Form>
            {/*<Row>*/}
            {/*    <span>Filter Loan Applications</span>*/}
            {/*</Row>*/}
            <Row className="mb-3">
                <Form.Group as={Col} controlId="filter_org">
                    <Form.Label>Organisation</Form.Label>
                    <Form.Select aria-label="Default select example" name="org_id" value={filterForm.org_id} onChange={handleChange}>
                        {orgOpts}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="filter_status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Default select example" name="status" value={filterForm.status}
                                 onChange={handleChange}>
                        <option value="">All</option>
                        <option value="created">Created</option>
                        <option value="failed">Failed</option>
                        <option value="visit_closed">Visit Closed</option>
                        <option value="verification_pending">Verification Pending/Review</option>
                        <option value="cam_available">CAM Available</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="filter_page">
                    <Form.Label>Page</Form.Label>
                    <Form.Select aria-label="Default select example" name="page" value={filterForm.page} onChange={handleChange}>
                        {pageOptions}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="filter_submit" className="btn-align">
                    <Button variant="primary" type="submit" className="btn-dark" onClick={triggerFetchApplications}>
                        Submit
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    )
    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const appsLoadingComponent = (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    const getBorrower = (application) => {
        let borrower = application.sections.filter(sec => sec.name === 'borrower_details')[0]
        return `${borrower.data.name?.value} (${borrower.data.mobile?.value})`
    }

    const appTableComponent = (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Application ID</th>
                <th>Borrower</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {
                applications && applications.map(application => (
                    <tr key={`app_${application._id}`}>
                        <td>{application._id}</td>
                        <td>{getBorrower(application)}</td>
                        <td>{application.status}</td>
                        <td>{new Date(application.created_at * 1000).toLocaleDateString()}</td>
                        <td><a href={`/applications/view/${application._id}`} className="btn btn-dark">View</a> </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        {
                            user.admin && (
                                <Nav className="ml-auto justify-content-end">
                                    <Nav.Link href="/organisations/list" ><FontAwesomeIcon icon={faList} />&nbsp;&nbsp;Organisation List</Nav.Link>
                                </Nav>
                            )
                        }
                    </div>
                </Col>
                <Col xs={12}>
                    {filterFormComponent}
                </Col>
                <Col xs={12}>
                    {appsLoading ? appsLoadingComponent : appTableComponent}
                </Col>
            </Row>
        </Container>
    );
}

export default ApplicationList;
