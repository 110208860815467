import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import ac_logo from '../../images/ac_logo.png';
import "../../css/auth.css";
import {appStore} from "../../utils/store";

const VerifyOTP = () => {
    const [otp, setOtp] = useState({
        otp: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState("");
    const { verifyOTP } = useAuth();
    const navigate = useNavigate();

    const handleChange = (ev) => {
        setOtp(ev.target.value);
        setError("");
        setShowAlert(false);
    };

    const handleVerify = (ev) => {
        ev.preventDefault();
        if (!otp) {
            setError("OTP is invalid");
            setShowAlert(true);
        }
        const authDetails = appStore.getValue('auth_details');
        if (!authDetails) {
            navigate("/login");
        }
        verifyOTP(otp).then(res => {
            if (!res) {
                setError("OTP verification failed")
                setShowAlert(true)
            }
        });
    };

    return (
        <Container>
            <Row>
                <Col>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row className="login-row">
                <Col xs={12}>
                </Col>
                <Col xs={7} className="right-border">
                    <Card className="card-custom">
                        <Card.Img variant="top" src={ac_logo}/>
                    </Card>
                    <Card className="card-custom">
                        <Card.Body>
                            <Card.Title className="card-title-nw">AI that writes Credit Reports on its own !!</Card.Title>
                            <Card.Text>
                                AbleCredit is your friendly, supportive credit assistant. It generates Credit Appraisal Memos based on your policies, without any human intervention.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={5}>
                    <h2>Verify OTP</h2>
                    <Form  onSubmit={handleVerify}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Enter OTP</Form.Label>
                            <Form.Control type="text" placeholder="" name="otp" onChange={handleChange} />
                            <Form.Text className="text-muted" name="otp" onChange={handleChange}>
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" className="btn-lg login-btn-nw" type="submit">
                            Verify OTP
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default VerifyOTP;
