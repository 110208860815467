import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function SWOT({dataKey, swot, handleOnChange}) {
    return (
        <Col xs={12}>
            <span className="main-heading">SWOT</span>
            {/*<Form.Group className="mb-3" controlId="summary">*/}
            {/*    <Form.Label>Summary</Form.Label>*/}
            {/*    <Form.Control as="textarea" name="summary" rows={12}*/}
            {/*                  value={SWOT.summary}*/}
            {/*                  onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>*/}
            {/*</Form.Group>*/}
            <Row>
                {/*<Col xs={12}><span className="sub-heading">Data</span> </Col>*/}
                <Col xs={12}>
                    <Form.Group className="mb-3" controlId="swot_strengths">
                        <Form.Label>Strengths</Form.Label>
                        <Form.Control as="textarea" name="swot_strengths" rows={12}
                                      value={swot.strengths}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.strengths`)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="swot_weaknesses">
                        <Form.Label>Weaknesses</Form.Label>
                        <Form.Control as="textarea" name="weaknesses" rows={12}
                                      value={swot.weaknesses}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.weaknesses`)}/>
                    </Form.Group>
                </Col>
            </Row>
        </Col>
    )
}

export default SWOT;