import React, {createContext, useState, useContext, useEffect} from 'react';
import {jsonReq} from "../utils/HttpUtil";
import '../utils/store'
import {appStore} from "../utils/store";
import {useNavigate} from "react-router-dom";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const navigate = useNavigate();
    const [orgRole, setOrgRole] = useState(() => {
        return appStore.getValue('ac_org_role');
    });

    useEffect(() => {
        if (orgRole){
            appStore.setValue('ac_org_role', orgRole);
        }
    },[orgRole]);

    const fetchHome = async () => {
        try {
            let res = await jsonReq().get("/dashboard/home");
            if (res && res.data) {
                const fOrgRole = {
                    organisation: res.data.data.organisation,
                    roles: res.data.data.roles,
                };
                setOrgRole(fOrgRole);
            }
        } catch (e) {
            console.log("Error fetching home: ", e);
            if (e.response && e.response.status === 401) {
                appStore.clear()
                navigate("/login")
            }
        }
    };

    return (
        <DataContext.Provider value={{ orgRole, fetchHome, setOrgRole }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
