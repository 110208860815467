import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {authenticateAxios, jsonReq} from '../utils/HttpUtil'
import '../utils/store'
import {appStore} from "../utils/store";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        const savedUser = appStore.getValue("ac_user")
        if (savedUser) {
            return savedUser;
        }
    });

    const navigate = useNavigate();

    useEffect(() => {
        // Save the authentication state to local storage whenever it changes
        appStore.setValue("ac_user", user);
    }, [user]);

    const sendOTP = async (channel, address, countryCode) => {
        let res = await jsonReq().post('/auth/login', {
            channel,
            address,
            country_code: countryCode
        });
        if(res) {
            appStore.setValue('auth_details', {
                channel: channel,
                address: address,
                country_code: countryCode
            });
            //navigate('/verify-otp');
            return true
        }
        return false
    };

    const verifyOTP = async (otp) => {
        const authDetails = appStore.getValue('auth_details');
        let res;
        try {
            res = await jsonReq().post('/auth/verify', {
                channel: authDetails.channel,
                address: authDetails.address,
                country_code: authDetails.country_code,
                otp,
            });
            if (res && res.data) {
                setUser(res.data.data.user);
                navigate('/dashboard');
                return true
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Invalid OTP. Please try again.');
            return false
        }
    };

    const logout = () => {
        setUser(null);
        jsonReq().post("/auth/logout", {})
        appStore.clear()
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, sendOTP, verifyOTP, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
