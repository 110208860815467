import Accordion from "react-bootstrap/Accordion";
import {getTitle} from "../../utils/TextUtil";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";

function CollateralImages({dataKey, collateral_images, handleOnChange}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="ci_summary">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control as="textarea" name="ci_summary" rows={12}
                                          value={collateral_images.summary}
                                          onChange={(ev) =>
                                              handleOnChange(ev, `${dataKey}.summary`)}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default CollateralImages;